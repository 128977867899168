@import "variables";

.cta-contacto {
	border-top: 1px solid $gray;

	.info {
		.inner {
			position: relative;
			width: calc(100% - 40px);
			margin: auto;
			padding-top: 6em;
		}
	}

	.side {
		background-color: $gray;
		padding-top: 3em;
		padding-bottom: 4em;
		margin-top: 3em;
		.inner {
			position: relative;
			width: calc(100% - 40px);
			margin: auto;
			.loc {
				position: relative;
				margin-bottom: 2em;
				.heading {
					display: inline-block;
					position: absolute;
					top: 0;
					left: 0;
					transform-origin: bottom left;
					transform: rotate(90deg) translateX(-1em);
					font-family: $fontPrimary;
					font-size: 14px;
					text-transform: uppercase;
					letter-spacing: 0.25em;
				}
				.txt {
					padding-left: 2.3em;
					a {
						color: $fontcolor;
						&:hover {
							color: $green;
						}
					}
				}
			}
		}
	}

	form {
		label {
			font-family: $fontSecondaryBold;
			font-size: 16px;
		}
		.form-control {
			background-color: $gray;
			border-color: mix($gray, black, 95%);
			border-radius: 0;
			&:focus {
				box-shadow: 0 0 0 0.25rem mix($gray, black, 99%);
			}
		}
	}

	@media #{$break-md} {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		.info {
			width: 50%;
			.inner {
				padding-bottom: 6em;
			}
		}
		.side {
			width: 50%;
			margin-top: 0;
			padding-top: 6em;
		}
	}

	@media #{$break-lg} {
		.info {
			display: flex;
			justify-content: flex-end;
			.inner {
				max-width: 520px;
			}
		}
		.side {
			display: flex;
			justify-content: flex-start;
			.inner {
				max-width: 520px;
			}
		}
	}
}
