.cta-contacto {
  border-top: 1px solid #f3f0ef;
}

.cta-contacto .info .inner {
  width: calc(100% - 40px);
  margin: auto;
  padding-top: 6em;
  position: relative;
}

.cta-contacto .side {
  background-color: #f3f0ef;
  margin-top: 3em;
  padding-top: 3em;
  padding-bottom: 4em;
}

.cta-contacto .side .inner {
  width: calc(100% - 40px);
  margin: auto;
  position: relative;
}

.cta-contacto .side .inner .loc {
  margin-bottom: 2em;
  position: relative;
}

.cta-contacto .side .inner .loc .heading {
  transform-origin: 0 100%;
  text-transform: uppercase;
  letter-spacing: .25em;
  font-family: AT Surt, Arial, sans-serif;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg)translateX(-1em);
}

.cta-contacto .side .inner .loc .txt {
  padding-left: 2.3em;
}

.cta-contacto .side .inner .loc .txt a {
  color: #000;
}

.cta-contacto .side .inner .loc .txt a:hover {
  color: #1cb577;
}

.cta-contacto form label {
  font-family: Larsseit Bold, Arial, sans-serif;
  font-size: 16px;
}

.cta-contacto form .form-control {
  background-color: #f3f0ef;
  border-color: #e7e4e3;
  border-radius: 0;
}

.cta-contacto form .form-control:focus {
  box-shadow: 0 0 0 .25rem #f1eeed;
}

@media (width >= 992px) {
  .cta-contacto {
    flex-direction: row;
    align-items: stretch;
    display: flex;
  }

  .cta-contacto .info {
    width: 50%;
  }

  .cta-contacto .info .inner {
    padding-bottom: 6em;
  }

  .cta-contacto .side {
    width: 50%;
    margin-top: 0;
    padding-top: 6em;
  }
}

@media (width >= 1200px) {
  .cta-contacto .info {
    justify-content: flex-end;
    display: flex;
  }

  .cta-contacto .info .inner {
    max-width: 520px;
  }

  .cta-contacto .side {
    justify-content: flex-start;
    display: flex;
  }

  .cta-contacto .side .inner {
    max-width: 520px;
  }
}

.main-banner {
  width: 100%;
  height: 100dvh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.main-banner .caption {
  z-index: 15;
  place-items: center;
  width: 100%;
  height: 100%;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

.main-banner .caption h1 {
  text-transform: uppercase;
  color: #000;
  margin-bottom: .6em;
  font-family: AT Surt, Arial, sans-serif;
  font-size: clamp(22px, 4vw, 48px);
  line-height: 1.45em;
}

.main-banner .caption p {
  font-size: 16px;
}

.main-banner .poster {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.main-banner .poster img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.icon-scroll {
  z-index: 50;
  border-radius: 25px;
  width: 25px;
  height: 40px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: inset 0 0 0 1px #000;
}

.icon-scroll:before {
  content: "";
  background: #000;
  border-radius: 4px;
  width: 4px;
  height: 4px;
  animation-name: scroll;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(28px);
  }
}

.introduccion {
  background-color: #f3f0ef;
  padding: 3em 0;
  position: relative;
  overflow: hidden;
}

.introduccion .wrapperRollingText {
  white-space: nowrap;
  z-index: 20;
  position: relative;
}

.introduccion .rollingText {
  color: #000;
  text-transform: uppercase;
  font-family: AT Surt, Arial, sans-serif;
  font-size: clamp(100px, 12vw, 144px);
  display: inline-block;
}

.introduccion .rollingText span {
  padding-right: 1em;
  display: inline-block;
}

.introduccion .rollingText span:after {
  content: "";
  background-color: #1cb577;
  width: .6em;
  height: .05em;
  display: inline-block;
  transform: translateX(.15em)translateY(-.4em);
}

.introduccion .columns {
  margin-top: 2em;
}

.introduccion .columns .col .item {
  margin-bottom: 3em;
}

.introduccion .columns .col .item figure img {
  width: 100%;
  height: auto;
}

.introduccion .columns .col .item .caption {
  color: #000;
  margin-top: 1em;
  font-family: AT Surt, Arial, sans-serif;
}

.introduccion .columns .col .item .caption .title {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: clamp(50px, 7vw, 144px);
  line-height: 1em;
}

.introduccion .columns .col .item .caption .title em {
  font-style: normal;
}

.introduccion .columns .col .item .caption .sub {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 14px;
}

@media (width >= 768px) {
  .introduccion .columns .col .item {
    flex-direction: row-reverse;
    justify-content: space-between;
    display: flex;
  }

  .introduccion .columns .col .item figure {
    width: 50%;
  }

  .introduccion .columns .col .item .caption {
    text-align: right;
    width: 50%;
    margin-top: 0;
    padding-right: 2em;
    position: relative;
  }

  .introduccion .columns .col:nth-child(2n) .item {
    flex-direction: row;
  }

  .introduccion .columns .col:nth-child(2n) .item .caption {
    text-align: left;
    padding-left: 2em;
    padding-right: 0;
  }
}

.portafolio {
  padding: 5em 0 2.5em;
  position: relative;
}

.portafolio .blocks .block {
  z-index: 20;
  border-top: 1px solid #f3f0ef;
  border-bottom: 1px solid #f3f0ef;
  position: relative;
  overflow: hidden;
}

.portafolio .blocks .block .cont-sup {
  flex-direction: column-reverse;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.portafolio .blocks .block .caption {
  z-index: 10;
  margin-top: -1em;
  position: relative;
}

.portafolio .blocks .block .caption .title {
  text-transform: uppercase;
  color: #000;
  margin: 0 0 .25em;
  font-family: AT Surt, Arial, sans-serif;
  font-size: clamp(42px, 4vw, 96px);
  line-height: 1.25em;
}

.portafolio .blocks .block .caption .logo {
  width: 150px;
  margin: 1em 0 2em;
}

.portafolio .blocks .block .caption .logo img {
  width: 100%;
  height: auto;
}

.portafolio .blocks .block .poster {
  z-index: 1;
  width: calc(100% + 40px);
  position: relative;
  transform: translateX(-20px);
}

.portafolio .blocks .block .poster .cover {
  z-index: 10;
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.portafolio .blocks .block .poster img {
  aspect-ratio: 1;
  z-index: 1;
  object-fit: cover;
  width: 100%;
  height: auto;
  position: relative;
}

.portafolio .blocks .block .poster .cc {
  z-index: 5;
  opacity: .3;
  width: 1059px;
  position: absolute;
  top: 25%;
  left: 25%;
}

@media (width >= 768px) {
  .portafolio .blocks .block .cont-sup {
    flex-direction: row;
    align-items: stretch;
    width: calc(100% - 40px);
    display: flex;
  }

  .portafolio .blocks .block .cont-sup .caption {
    width: 25%;
    margin-top: 0;
    padding-top: 3em;
  }

  .portafolio .blocks .block .cont-sup .poster {
    width: 75%;
    margin-bottom: 5em;
    transform: translateX(0);
  }

  .portafolio .blocks .block:nth-child(2n) .cont-sup {
    flex-direction: row-reverse;
  }

  .portafolio .blocks .block:nth-child(2n) .cont-sup .caption {
    text-align: right;
  }

  .portafolio .blocks .block:nth-child(2n) .cont-sup .caption .logo {
    margin-left: auto;
  }
}

@media (width >= 992px) {
  .portafolio .blocks .block .cont-sup .caption {
    transform: translateX(1em);
  }

  .portafolio .blocks .block .cont-sup .poster img {
    aspect-ratio: 4 / 3;
  }

  .portafolio .blocks .block:nth-child(2n) .cont-sup .caption {
    transform: translateX(-1em);
  }
}

.quote-convivencia {
  padding: 3em 0 5em;
  position: relative;
}

.quote-convivencia .frase {
  text-transform: uppercase;
  color: #e8e8e8;
  font-family: Larsseit Light, Arial, sans-serif;
  font-size: clamp(42px, 9vw, 130px);
  font-style: normal;
  font-weight: 400;
  line-height: 1.2em;
  display: inline-block;
}

.quote-convivencia .frase .txt {
  display: inline-block;
  position: relative;
}

.quote-convivencia .frase .txt span {
  z-index: 10;
  color: #000;
  background-color: #1cb577;
  width: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

/*# sourceMappingURL=index.a79d5a9b.css.map */
