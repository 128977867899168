$fontPrimary: "AT Surt", Arial, sans-serif;
$fontSecondaryLight: "Larsseit Light", Arial, sans-serif;
$fontSecondaryBold: "Larsseit Bold", Arial, sans-serif;

/* colors */
$fontcolor: #000;
$gray: #f3f0ef;
$gray2: #e8e8e8;
$green: #1cb577;

/* media-queries */
$break-xl: "(min-width: 1400px)";
$break-lg: "(min-width: 1200px)";
$break-md: "(min-width: 992px)";
$break-sm: "(min-width: 768px)";
$break-xs: "(min-width: 576px)";
$break-480: "(min-width: 480px)";
$break-420: "(min-width: 420px)";
$break-320: "(min-width: 320px)";
