@import "variables";
@import "cta-contacto";

.main-banner {
	overflow: hidden;
	width: 100%;
	height: 100vh;
	height: 100dvh;
	position: relative;
	display: flex;
	.caption {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 15;
		display: grid;
		place-items: center;
		h1 {
			font-family: $fontPrimary;
			font-size: 48px;
			font-size: clamp(22px, 4vw, 48px);
			line-height: 1.45em;
			text-transform: uppercase;
			color: $fontcolor;
			margin-bottom: 0.6em;
		}
		p {
			font-size: 16px;
		}
	}
	.poster {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.icon-scroll {
	position: absolute;
	width: 25px;
	height: 40px;
	left: 50%;
	bottom: 20px;
	box-shadow: inset 0 0 0 1px #000;
	border-radius: 25px;
	transform: translateX(-50%);
	z-index: 50;
	&:before {
		content: "";
		width: 4px;
		height: 4px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		background: #000;
		top: 8px;
		border-radius: 4px;
		-webkit-animation-duration: 1.5s;
		animation-duration: 1.5s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		-webkit-animation-name: scroll;
		animation-name: scroll;
	}
}
@-webkit-keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(28px);
		transform: translateY(28px);
	}
}
@keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(28px);
		transform: translateY(28px);
	}
}

.introduccion {
	padding: 3em 0;
	position: relative;
	background-color: $gray;
	overflow: hidden;
	.wrapperRollingText {
		white-space: nowrap;
		position: relative;
		z-index: 20;
	}

	.rollingText {
		display: inline-block;
		// padding-left: 10px;
		color: #000;
		font-family: $fontPrimary;
		font-size: 144px;
		font-size: clamp(100px, 12vw, 144px);
		text-transform: uppercase;
		span {
			display: inline-block;
			padding-right: 1em;
			&::after {
				content: "";
				display: inline-block;
				width: 0.6em;
				height: 0.05em;
				background-color: $green;
				transform: translateX(0.15em) translateY(-0.4em);
			}
		}
	}
	.columns {
		margin-top: 2em;
		.col {
			.item {
				margin-bottom: 3em;
				figure {
					img {
						width: 100%;
						height: auto;
					}
				}
				.caption {
					color: $fontcolor;
					font-family: $fontPrimary;
					margin-top: 1em;
					.title {
						font-size: 144px;
						font-size: clamp(50px, 7vw, 144px);
						line-height: 1em;
						text-transform: uppercase;
						white-space: nowrap;
						em {
							font-style: normal;
						}
					}
					.sub {
						font-size: 14px;
						letter-spacing: 2px;
						text-transform: uppercase;
					}
				}
			}
		}
	}

	@media #{$break-sm} {
		.columns {
			.col {
				.item {
					display: flex;
					flex-direction: row-reverse;
					justify-content: space-between;
					figure {
						width: 50%;
					}
					.caption {
						width: 50%;
						text-align: right;
						margin-top: 0;
						padding-right: 2em;
						position: relative;
					}
				}
				&:nth-child(even) {
					.item {
						flex-direction: row;
						.caption {
							text-align: left;
							padding-right: 0;
							padding-left: 2em;
						}
					}
				}
			}
		}
	}
}

.portafolio {
	position: relative;
	padding: 5em 0 2.5em 0;
	.blocks {
		.block {
			border-top: 1px solid $gray;
			border-bottom: 1px solid $gray;
			position: relative;
			z-index: 20;
			overflow: hidden;
			.cont-sup {
				display: flex;
				flex-direction: column-reverse;
				padding-right: 0;
				padding-left: 0;
			}
			.caption {
				position: relative;
				z-index: 10;
				margin-top: -1em;
				.title {
					font-family: $fontPrimary;
					font-size: 96px;
					font-size: clamp(42px, 4vw, 96px);
					text-transform: uppercase;
					color: $fontcolor;
					margin: 0 0 0.25em 0;
					line-height: 1.25em;
				}
				.logo {
					width: 150px;
					margin: 1em 0 2em 0;
					img {
						width: 100%;
						height: auto;
					}
				}
			}
			.poster {
				width: calc(100% + 40px);
				transform: translateX(-20px);
				position: relative;
				z-index: 1;
				.cover {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: #fff;
					z-index: 10;
				}
				img {
					width: 100%;
					height: auto;
					aspect-ratio: 1/1;
					position: relative;
					z-index: 1;
					object-fit: cover;
				}
				.cc {
					position: absolute;
					top: 25%;
					left: 25%;
					width: 1059;
					z-index: 5;
					opacity: 0.3;
				}
			}
		}
	}

	@media #{$break-sm} {
		.blocks {
			.block {
				.cont-sup {
					display: flex;
					flex-direction: row;
					align-items: stretch;
					width: calc(100% - 40px);
					.caption {
						width: 25%;
						margin-top: 0;
						padding-top: 3em;
					}
					.poster {
						width: 75%;
						transform: translateX(0px);
						margin-bottom: 5em;
					}
				}
				&:nth-child(even) {
					.cont-sup {
						flex-direction: row-reverse;
						.caption {
							text-align: right;
							.logo {
								margin-left: auto;
							}
						}
					}
				}
			}
		}
	}

	@media #{$break-md} {
		.blocks {
			.block {
				.cont-sup {
					.caption {
						transform: translateX(1em);
					}
					.poster {
						img {
							aspect-ratio: 4/3;
						}
					}
				}
				&:nth-child(even) {
					.cont-sup {
						.caption {
							transform: translateX(-1em);
						}
					}
				}
			}
		}
	}
}

.quote-convivencia {
	position: relative;
	padding: 3em 0 5em;
	.frase {
		font-family: $fontSecondaryLight;
		font-style: normal;
		font-weight: 400;
		font-size: 130px;
		font-size: clamp(42px, 9vw, 130px);
		line-height: 1.2em;
		text-transform: uppercase;
		color: $gray2;
		display: inline-block;
		.txt {
			position: relative;
			display: inline-block;
			span {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 10;
				background-color: $green;
				color: $fontcolor;
				display: block;
				width: 0;
				overflow: hidden;
			}
		}
	}
}
